import type { PropsWithChildren } from 'react';
import { createContext, useCallback, useMemo, useState } from 'react';

import { useAuthedRequest } from '~/hooks/use-authed-request';

import type { Organization } from '~/types';

import noop from 'lodash/noop';

// Create Context Object
export const OrganizationListContext = createContext<{
  organizationList: any[];
  organizationById: any;
  reload: () => void;
}>({
  organizationList: [],
  organizationById: {},
  reload: noop,
});

// Create a provider for components to consume and subscribe to changes
export const OrganizationListProvider = ({ children, organizations }: PropsWithChildren<{ organizations: any[] }>) => {
  const [organizationList, setOrganizations] = useState<any[]>(organizations);
  const { get } = useAuthedRequest(true);

  const load = useCallback(() => {
    get(`/organizations`, { withCredentials: false })
      .then((organizations) => {
        setOrganizations(
          organizations.data.map(({ id, name, slug }: Organization) => ({ label: name, value: id, slug })),
        );
      })
      .catch((err) => {
        console.error(err);
      });
  }, [get]);

  const organizationById = useMemo(
    () => organizationList.reduce((acc, org) => ({ ...acc, [org.value]: org.label }), {}),
    [organizationList],
  );

  return (
    <OrganizationListContext.Provider
      value={{
        organizationList,
        organizationById,
        reload: load,
      }}
    >
      {children}
    </OrganizationListContext.Provider>
  );
};
